import React, { ReactElement, ReactNode, Suspense } from 'react'
import { Outlet, ScrollRestoration } from 'react-router-dom'

import { AppBar, Slide, Stack, useScrollTrigger } from '@mui/material'

import { config } from '../../config'
import { SceneSpinner } from '../SceneSpinner'
import { Footer } from './components/Footer'
import { GeoSpecificModal } from './components/GeoSpecificModal'
import { Header } from './components/Header'
import { WHITELABELS_FOR_SHOWING_GEO_SPECIFIC_MODAL } from './constants'

type CommonLayoutProps = {
  children?: ReactNode
}

export function CommonLayout(props: CommonLayoutProps): ReactElement {
  const { children = <Outlet /> } = props

  const trigger = useScrollTrigger()

  return (
    <>
      {WHITELABELS_FOR_SHOWING_GEO_SPECIFIC_MODAL.includes(config.whitelabel) && <GeoSpecificModal />}
      <Stack
        alignItems="center"
        direction="column"
        minHeight="100%"
        position="relative"
        sx={{ backgroundColor: '#A7A7A7' }}
        width="100%"
      >
        <Stack position="relative" width="100%">
          <Slide appear={false} direction="down" in={!trigger}>
            <AppBar position="sticky">
              <Header />
            </AppBar>
          </Slide>
          <Stack
            alignItems="center"
            flex="1 1 0"
            minWidth={0}
            position="relative"
            sx={{ backgroundColor: '#FFF' }}
            width={'100%'}
          >
            <Suspense fallback={<SceneSpinner />}>
              <ScrollRestoration />
              {children}
            </Suspense>
          </Stack>
          <Footer />
        </Stack>
      </Stack>
    </>
  )
}
