import { i18n } from '@lingui/core'
import invariant from 'tiny-invariant'

import { config } from '../../config'
import { defaultLocale, Locale, LOCALE_STORAGE_KEY, locales } from './constants'
import { getWhitelabelTranslationData } from './utils'

const AllowedLocales = Object.keys(locales)

const isValidLocale = (value: unknown): value is Locale => AllowedLocales.includes(value as Locale)

export function getInitialLocale(): Locale {
  const localStorageLang = localStorage.getItem(LOCALE_STORAGE_KEY)

  if (localStorageLang != null && isValidLocale(localStorageLang)) return localStorageLang

  return defaultLocale
}

export function activateLocale(locale: Locale): void {
  invariant(isValidLocale(locale))

  localStorage.setItem(LOCALE_STORAGE_KEY, locale)

  const translatesPromise = getWhitelabelTranslationData(config.whitelabel)

  translatesPromise[locale].then(({ localeData, messages }) => {
    i18n.load({ [locale]: messages })
    i18n.loadLocaleData({ [locale]: localeData })
    i18n.activate(locale)
  })
}
