import { lazy } from '../lazy'
import { Locale, Translate } from './constants'

const arTauroTranslationData = lazy(() => import('../../locales/ar'))
const enTauroTranslationData = lazy(() => import('../../locales/en'))
const arMauTranslationData = lazy(() => import('../../locales/ar-mau'))
const enMauTranslationData = lazy(() => import('../../locales/en-mau'))

const whitelabelTranslationDataMap = new Map<string, Record<Locale, Promise<Translate>>>([
  ['tauroMarkets', { ar: arTauroTranslationData, en: enTauroTranslationData }],
  ['tauroMarketsMauritius', { ar: arMauTranslationData, en: enMauTranslationData }],
])

export function getWhitelabelTranslationData(whitelabel: string): Record<Locale, Promise<Translate>> {
  return (
    whitelabelTranslationDataMap.get(whitelabel) ?? {
      ar: arTauroTranslationData,
      en: enTauroTranslationData,
    }
  )
}
